.convert_button{
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px 2px;
  cursor: pointer;
  border-radius: 10px;
}

.convert_button:hover{
  background-color: #45a049;
}

.download_button{
  background-color: orange;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px 2px;
  cursor: pointer;
  border-radius: 10px;
}

.download_button:hover{
  background-color: rgb(236, 154, 0);
}

.audio_controlls{
  margin-top: 5px;
}

.input_field{
  field-sizing: content;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 7px;
  font-size: 16px;
  max-width: 90%;
}

.checkbox_input{
  margin-left: 8px;
  transform: scale(1.3); /* Increase size of checkbox */
}

.header_title{
  color: white;
}